import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Frontpage.css";
import greenGlosy from "./images/RoundCube-2.png";
import ring from "./images/SuperToroidBlueGlossy.png";
import "bootstrap/dist/css/bootstrap.min.css";
import TextBar from "./Textbar";
import Navbar from "./Navbar";

function FrontPage({setScaling}) {
  return (
    <>
      <Navbar setScaling={setScaling}/>
      <div className="front-section d-flex align-items-center">
        <img src={greenGlosy} className="green-glosy-image" alt="Green Glosy" />
        <div>
          <div className="pink-image" alt="Pink-Image"></div>
        </div>
        <TextBar setScaling={setScaling}/>
        <img src={ring} className="ring-box" alt="Ring-Image" />
      </div>
    </>
  );
}

export default FrontPage;
