import React, { useState } from "react";
import "./Navbar.css";
import logo from "./images/logo.png";
import { MdOutlineMenu } from "react-icons/md";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";

function Navbar({ setScaling }) {
  const [click, setClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery("(max-width: 500px)");
  const open = Boolean(anchorEl);

  const handleEmailClick = () => {
    const recipient = "Sooray@quasarinsightlabs.in";
    const subject = "Notification";
    const body = "The Reach image was clicked!";
    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };

  const toggleMenu = () => {
    setClick(!click);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    toggleMenu();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav className="navbar">
      <div
        className="logo"
        onMouseEnter={() => setScaling(true)}
        onMouseLeave={() => setScaling(false)}
      >
        <a href="#home">
          <img src={logo} alt="Logo" />
        </a>
      </div>
      {isMobile ? (
        <div className="menu-bar" onClick={handleClick}>
          <MdOutlineMenu
            className="HamburgerMenu"
            size="30px"
            color="rgba(255, 255, 255, 1)"
          />
        </div>
      ) : (
        <ul
          className={`nav-links ${click ? "active" : "inActive"}`}
          onMouseEnter={() => setScaling(true)}
          onMouseLeave={() => setScaling(false)}
        >
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li onClick={handleEmailClick}>
            <a href="#contact-us">Contact Us</a>
          </li>
        </ul>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <a href="#home">Home</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="#services">Services</a>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <a href="#contact-us" onClick={handleEmailClick}>
            Contact Us
          </a>
        </MenuItem>
      </Menu>
    </nav>
  );
}

export default Navbar;
