import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/Textbar.css";
import sphereImage from "./images/SphereOrangeGlossy.png";
import cube from "./images/RoundCubeIridescent.png";
import cone from "./images/Cone.png";
import dotted from "./images/dotted.png";
const handleEmailClick = () => {
  const recipient = "Sooray@quasarinsightlabs.in";
  const subject = "Notification";
  const body = "The Reach image was clicked!";
  window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
};

const TextBar = ({setScaling}) => {
  return (
    <header>
      <div class="header-content">
        <div class="back">
          <img src={dotted} alt="Dotted" className="img-fluid dotted-img" />
        </div>
        <div>
          <img src={sphereImage} className="sphere-image" alt="Sphere-Image" />
        </div>
        <div className="cube-container">
          <img src={cube} className="cube-image" alt="Cube-Image" />

          <div>
            <img src={cone} className="cone-image" alt="Cone-Image" />
          </div>
        </div>

        <h2>Let’s Grow Together</h2>
        <div className="btn-container">
          <button
          onMouseEnter={() => setScaling(true)}
          onMouseLeave={() => setScaling(false)}
          class="buttons" onClick={handleEmailClick}>
            <span class="blue-box"></span>
            Reach Us
          </button>
        </div>
      </div>
    </header>
  );
};

export default TextBar;

