import React, { useState } from "react";
import "./App.css";
import FrontPage from "./components/Frontpage";
import ServicePage from "./components/servicePage/Services";
import Cursor from "./components/cursor/Cursor";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_apiKey,
    authDomain: process.env.REACT_APP_authDomain,
    projectId: process.env.REACT_APP_projectId,
    storageBucket: process.env.REACT_APP_storageBucket,
    messagingSenderId: process.env.REACT_APP_messagingSenderId,
    appId: process.env.REACT_APP_appId,
    measurementId: process.env.REACT_APP_measurementId,
  };

  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  
  // Initialize Analytics and get a reference to the service
  const analytics = getAnalytics(app);

  const [scaling, setScaling] = useState(false);

  return (
    <div className="App">
      {/* <Cursor scaling={scaling}> */}
        <div id="home">
          <FrontPage setScaling={setScaling} />
        </div>
        <div id="services">
          <ServicePage setScaling={setScaling} />
        </div>
      {/* </Cursor> */}
    </div>
  );
}

export default App;
